.container {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageArea {
  width: 100%;
  margin-bottom: 5px;
}

.captionArea {
  width: 100%;
  text-align: center;
  font-weight: 500;
}
