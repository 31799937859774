.heightLimiter {
  background-color: white;
}

.ratioBox {
  box-sizing: border-box;

  position: relative;
}

.contentWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  border-radius: 5px;
}

.textArea {
  overflow-y: auto;
}

@media screen and (min-width: 768px) {
  .widthLimiter {
    width: 80vw;
    max-width: 144vh;
  }
  .heightLimiter {
    height: 50vw;
    max-height: 90vh;
    display: flex;
  }
  .imageArea {
    width: 50vw;
    max-width: 90vh;
    height: 50vw;
    max-height: 90vh;

    background-color: black;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .imageItself {
    max-width: 100%;
    max-height: 100%;
  }
  .textArea {
    width: 30vw;
    max-width: 54vh;
    height: 50vw;
    max-height: 90vh;
    padding: 20px;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 768px) {
  .widthLimiter {
    width: 80vw;
    max-width: 400px;
    max-height: 80vh;

    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .widthLimiter::-webkit-scrollbar {
    display: none;
  }
  .heightLimiter {
    display: flex;
    flex-direction: column;
  }
  .imageArea {
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: center;
  }
  .imageItself {
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
  }
  .textArea {
    width: 100%;
    height: fit-content;
    padding: 10px;
    box-sizing: border-box;
  }
}
