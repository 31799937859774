.menuContainer {
  display: flex;
}

@media screen and (min-width: 768px) {
  .menuContainer {
    flex-direction: row;
  }
}

@media screen and (max-width: 768px) {
  .menuContainer {
    flex-direction: column;
    align-items: center;
    border-top: 1px solid lightgray;
  }

  .hidden {
    display: none;
  }
}
