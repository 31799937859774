.buttonArea {
  font-family: "Roboto Slab", serif;
  font-weight: 300;
  color: black;

  background-color: white;
  border: none;

  height: 100%;
  padding-left: 15px;
  padding-right: 15px;

  cursor: pointer;
}

.buttonArea:hover {
  background-color: rgb(238, 237, 237);
}

@media screen and (min-width: 768px) {
  .buttonArea {
    font-size: 22px;
  }
}

@media screen and (max-width: 768px) {
  .buttonArea {
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
  }
}
