.contentWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 30px;
}
.paragraphArea {
  flex: 1;
}
.alignLeft {
  text-align: left;
}
.alignRight {
  text-align: right;
}

@media screen and (min-width: 768px) {
  .contentWrapper {
    align-items: stretch;
  }
  .imageLeft {
    flex-direction: row;
  }
  .imageRight {
    flex-direction: row-reverse;
  }
  .imageAreaSmall {
    width: 25%;
  }
  .imageAreaMedium {
    width: 35%;
  }
  .imageAreaBig {
    width: 45%;
  }
  .imageAreaLeft {
    margin-right: 7%;
  }
  .imageAreaRight {
    margin-left: 7%;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .imageLeft {
    flex-direction: row;
  }
  .imageRight {
    flex-direction: row-reverse;
  }
  .imageAreaSmall {
    width: 182px;
  }
  .imageAreaMedium {
    width: 254px;
  }
  .imageAreaBig {
    width: 327px;
  }
  .imageAreaLeft {
    margin-right: 7%;
  }
  .imageAreaRight {
    margin-left: 7%;
  }
}

@media screen and (max-width: 480px) {
  .contentWrapper {
    align-items: center;
  }
  .imageLeft,
  .imageRight {
    flex-direction: column;
  }
  .imageAreaLeft,
  .imageAreaRight {
    width: 75%;
    margin-bottom: 20px;
  }
  .paragraphArea {
    flex: 1;
    text-align: center;
  }
  .alignLeft {
    text-align: left;
  }
  .alignRight {
    text-align: right;
  }
}
