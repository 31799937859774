.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .wrapper {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    position: absolute;
    width: 46px;
    height: 46px;

    right: 0px;
  }
  .icon {
    width: 20px;
  }
}
