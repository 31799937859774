.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  padding: 20px;
}

@media screen and (min-width: 768px) {
  .contentWrapper {
    width: 768px;
  }
}

@media screen and (min-width: 480px) and (max-width: 768px) {
  .contentWrapper {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .contentWrapper {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
