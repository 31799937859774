body {
  margin: 0;
  font-family: "Roboto Slab", serif;
  font-size: 18px;
  font-weight: 400;
  background-color: rgb(238, 237, 237);
  width: 100vw;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 12px;
  background-color: none;
}
body::-webkit-scrollbar-track {
  background-color: transparent;
}
body::-webkit-scrollbar-thumb {
  background-color: darkgray;
  border-radius: 6px;
}

a,
a:link,
a:visited {
  text-decoration: none;
  color: #42c2ff;
}

li {
  margin-top: 10px;
  margin-bottom: 10px;
}

span {
  line-height: 1.5;
  margin-top: 5px;
  margin-bottom: 5px;
}

p:first-child {
  margin-top: 0;
}
