.footer {
  background-color: rgb(238, 237, 237);
  margin-top: 30px;
  border-top: 1px solid lightgray;
  padding-top: 30px;
  padding-bottom: 50px;
}

.contentWrapper {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  color: gray;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}

.leftParagraph {
  text-align: left;
}
.rightParagraph {
  text-align: right;
}

@media screen and (min-width: 768px) {
  .contentWrapper {
    width: 768px;
  }
}

@media screen and (max-width: 768px) {
  .contentWrapper {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
  }
  .leftParagraph {
    margin-bottom: 20px;
  }
  .rightParagraph {
    text-align: left;
  }
}
