.row {
  display: flex;
  flex-direction: row;
}

.cell {
  flex: 1;
  position: relative;
  cursor: pointer;
}
.cell:last-child {
  margin-right: 0;
}

.falseCell {
  cursor: auto;
}

@media screen and (min-width: 768px) {
  .cell {
    margin-right: 3%;
  }
  .row {
    margin-bottom: 3%;
  }
}

@media screen and (max-width: 768px) {
  .cell {
    margin-right: 1%;
  }
  .row {
    margin-bottom: 1%;
  }
}
